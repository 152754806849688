function send_form(id) {                                    // ------------------------------------------- send mail function
    var msg   = $('#'+id+'').serialize();
    var e   =  msg;
    var msg2 = msg.split('&');
    var msg3 = msg2[1].split('=');
    if (!msg3[1]=='') {
        $.ajax({
            type: "POST",
            url: "include/send.php",
            data: e,
            success: function(e) {
                $(".close").click(); 
				$("#thx").click();
				
            },
            error: function(e, c) {
                alert("Возникла ошибка: " + e.responseCode)
            }
        });
    } else {
        $('#'+id+'').find('[name = "phone_f"]').css({
            'border-color' : 'red'
        });
    }
};

function send_withfile(id) {                                // ------------------------------------------- send mail function with file
	var formData = new FormData($('#'+id+'')[0]);
	var msg   = $('#'+id+'').serialize();
	var msg2 = msg.split('&');
	var msg3 = msg2[2].split('=');
	console.log(msg3);
	if (!msg3[1]=='') {
		$.ajax({
			type: "POST",
			processData: false,
			contentType: false,
			url: "include/contacts.php",
			data:  formData,
			success: function(formData) {
				$(".close").click();
				$("#thx").click();
				
			},
			error: function(e, c) {
				alert("Возникла ошибка: " + e.responseCode)
			}
		});
	} else {
		$('#'+id+'').find('[name = "contactFF"]').css({
			'border-color' : 'red'
		});
	}
};

$(".icon-s-file").click(function () {                       // ------------------------------ for adding file
	var $i_id = $(this).attr("id");
	console.log($i_id);
	var $p_id = $i_id + "_file";
	console.log($p_id);
	var $ip_id = $p_id + "_input";
	console.log($ip_id);
	$('#'+$ip_id+'').click();
	$('#'+$ip_id+'').change(function () {
		$('#'+$p_id+'').text("Файл прикреплен");
	});

});
$(".link_file").click(function () {
	var $p_id = $(this).attr("id");
	var $i_id = $p_id + "_input";
	$('#'+$i_id+'').click();
	$('#'+$i_id+'').change(function () {
		$('#'+$p_id+'').text("Файл прикреплен");
	});
});

$('input[name=phone_f]').mask('+7 ( 999 ) 999-99-99');      // -------------------------------------------  mask for phone
$('input[name=contactFF]').mask('+7 ( 999 ) 999-99-99');

$('input.name_input').keypress(function (key) {           // ------------------------------------------- value for name input
    if (key.charCode < 48 || key.charCode > 57) return true;
    return false;
});

$("#answers").owlCarousel({                               // -------------------------------------------  carousel
    loop: true,
    margin: 10,
    mouseDrag: true,
    touchDrag: true,
    nav: true,
    navText: ['<i class="icon icon-s-link_left">', '<i class="icon icon-s-link_right">'],
    responsive: {
        0: {
            items: 1
        },
        450: {
            items: 2
        },
		770: {
			items: 3
		},
        1100: {
            items: 4
        }
    }
});

$("body").on('click', 'a.go_to', function(e){               // -------------------------------------------  scroll function
    var fixed_offset = 100;
    $('html,body').stop().animate({ scrollTop: $(this.hash).offset().top - fixed_offset }, 1000);
    e.preventDefault();
});

wow = new WOW(                                              // ------------------------------------------- WOW initialization
    {
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: false,
        live: true
    }
)
wow.init();
lightbox.option({
    'resizeDuration': 200,
    'wrapAround': true,
    'albumLabel': ""
});


$("img.lazy").lazyload();                                   // ------------------------------------------- lazyload for images


$(".check_block").click(function() {
    if($(this).hasClass('active')){
        $(this).removeClass('active');
        $(this).parent().siblings(".btn_group").find('button').attr('disabled','disabled');
    }
    else{
        $(this).addClass('active');
        $(this).parent().siblings(".btn_group").find('button').removeAttr( "disabled" );
    }
});

$( "#map1" ).click(function() {
	$("ul.map_list li").removeClass('active');
	$(this).addClass('active');
	init();
});
$( "#map2" ).click(function() {
	$("ul.map_list li").removeClass('active');
	$(this).addClass('active');
	init2();
});

// ========================================= map

google.maps.event.addDomListener(window, 'load', init);
var map, markersArray = [];

function bindInfoWindow(marker, map, location) {
    google.maps.event.addListener(marker, 'click', function() {
        function close(location) {
            location.ib.close();
            location.infoWindowVisible = false;
            location.ib = null;
        }

        if (location.infoWindowVisible === true) {
            close(location);
        } else {
            markersArray.forEach(function(loc, index){
                if (loc.ib && loc.ib !== null) {
                    close(loc);
                }
            });

            var boxText = document.createElement('div');
            boxText.style.cssText = 'background: #fff;';
            boxText.classList.add('md-whiteframe-2dp');

            function buildPieces(location, el, part, icon) {
                if (location[part] === '') {
                    return '';
                } else if (location.iw[part]) {
                    switch(el){
                        case 'photo':
                            if (location.photo){
                                return '<div class="iw-photo" style="background-image: url(' + location.photo + ');"></div>';
                            } else {
                                return '';
                            }
                            break;
                        case 'iw-toolbar':
                            return '<div class="iw-toolbar"><h3 class="md-subhead">' + location.title + '</h3></div>';
                            break;
                        case 'div':
                            switch(part){
                                case 'email':
                                    return '<div class="iw-details"><i class="material-icons" style="color:#4285f4;"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><span><a href="mailto:' + location.email + '" target="_blank">' + location.email + '</a></span></div>';
                                    break;
                                case 'web':
                                    return '<div class="iw-details"><i class="material-icons" style="color:#4285f4;"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><span><a href="' + location.web + '" target="_blank">' + location.web_formatted + '</a></span></div>';
                                    break;
                                case 'desc':
                                    return '<label class="iw-desc" for="cb_details"><input type="checkbox" id="cb_details"/><h3 class="iw-x-details">Details</h3><i class="material-icons toggle-open-details"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><p class="iw-x-details">' + location.desc + '</p></label>';
                                    break;
                                default:
                                    return '<div class="iw-details"><i class="material-icons"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><span>' + location[part] + '</span></div>';
                                    break;
                            }
                            break;
                        case 'open_hours':
                            var items = '';
                            if (location.open_hours.length > 0){
                                for (var i = 0; i < location.open_hours.length; ++i) {
                                    if (i !== 0){
                                        items += '<li><strong>' + location.open_hours[i].day + '</strong><strong>' + location.open_hours[i].hours +'</strong></li>';
                                    }
                                    var first = '<li><label for="cb_hours"><input type="checkbox" id="cb_hours"/><strong>' + location.open_hours[0].day + '</strong><strong>' + location.open_hours[0].hours +'</strong><i class="material-icons toggle-open-hours"><img src="//cdn.mapkit.io/v1/icons/keyboard_arrow_down.svg"/></i><ul>' + items + '</ul></label></li>';
                                }
                                return '<div class="iw-list"><i class="material-icons first-material-icons" style="color:#4285f4;"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><ul>' + first + '</ul></div>';
                            } else {
                                return '';
                            }
                            break;
                    }
                } else {
                    return '';
                }
            }

            boxText.innerHTML =
                buildPieces(location, 'photo', 'photo', '') +
                buildPieces(location, 'iw-toolbar', 'title', '') +
                buildPieces(location, 'div', 'address', 'location_on') +
                buildPieces(location, 'div', 'web', 'public') +
                buildPieces(location, 'div', 'email', 'email') +
                buildPieces(location, 'div', 'tel', 'phone') +
                buildPieces(location, 'div', 'int_tel', 'phone') +
                buildPieces(location, 'open_hours', 'open_hours', 'access_time') +
                buildPieces(location, 'div', 'desc', 'keyboard_arrow_down');

            var myOptions = {
                alignBottom: true,
                content: boxText,
                disableAutoPan: true,
                maxWidth: 0,
                pixelOffset: new google.maps.Size(-140, -40),
                zIndex: null,
                boxStyle: {
                    opacity: 1,
                    width: '280px'
                },
                closeBoxMargin: '0px 0px 0px 0px',
                infoBoxClearance: new google.maps.Size(1, 1),
                isHidden: false,
                pane: 'floatPane',
                enableEventPropagation: false
            };

            location.ib = new InfoBox(myOptions);
            location.ib.open(map, marker);
            location.infoWindowVisible = true;
        }
    });
}

function init() {
    var mapOptions = {
        center: new google.maps.LatLng(55.7688354,37.6189445),
        zoom: 14,
        gestureHandling: 'auto',
        fullscreenControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        draggable : true,
        clickableIcons: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#193341"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#2c5a71"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#29768a"},{"lightness":-37}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#3e606f"},{"weight":2},{"gamma":0.84}]},{"elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"weight":0.6},{"color":"#1a3541"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#2c5a71"}]}]
    }
    var mapElement = document.getElementById('map');
    var map = new google.maps.Map(mapElement, mapOptions);
    var locations = [
        {"title":"1-й Колобовский пер., 19с3","address":"1-й Колобовский пер., 19с3, Москва, Россия, 127051","desc":"","tel":"","int_tel":"","email":"","web":"","web_formatted":"","open":"","time":"","lat":55.7688354,"lng":37.6189445,"vicinity":"1-й Колобовский пер., 19с3, Москва, Россия, 127051","open_hours":"","marker":{"url":"images/marker.png","scaledSize":{"width":25,"height":34,"f":"px","b":"px"},"origin":{"x":0,"y":0},"anchor":{"x":12,"y":42}},"iw":{"address":true,"desc":true,"email":true,"enable":true,"int_tel":true,"open":true,"open_hours":true,"photo":true,"tel":true,"title":true,"web":true}}
    ];
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            icon: locations[i].marker,
            position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
            map: map,
            title: locations[i].title,
            address: locations[i].address,
            desc: locations[i].desc,
            tel: locations[i].tel,
            int_tel: locations[i].int_tel,
            vicinity: locations[i].vicinity,
            open: locations[i].open,
            open_hours: locations[i].open_hours,
            photo: locations[i].photo,
            time: locations[i].time,
            email: locations[i].email,
            web: locations[i].web,
            iw: locations[i].iw
        });
        markersArray.push(marker);

        if (locations[i].iw.enable === true){
            bindInfoWindow(marker, map, locations[i]);
        }
    }
}
function init2() {
    var mapOptions = {
        center: new google.maps.LatLng(55.7998466,37.57531960000006),
        zoom: 14,
        gestureHandling: 'auto',
        fullscreenControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: false,
        streetViewControl: false,
        draggable : true,
        clickableIcons: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#193341"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#2c5a71"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#29768a"},{"lightness":-37}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#3e606f"},{"weight":2},{"gamma":0.84}]},{"elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"weight":0.6},{"color":"#1a3541"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#2c5a71"}]}]
    }
    var mapElement = document.getElementById('map');
    var map = new google.maps.Map(mapElement, mapOptions);
    var locations = [
        {"title":"Башиловская ул., 19","address":"Башиловская ул., 19, Москва, Россия, 127287","desc":"","tel":"","int_tel":"","email":"","web":"","web_formatted":"","open":"","time":"","lat":55.7998466,"lng":37.57531960000006,"vicinity":"Башиловская ул., 19, Москва, Россия, 127287","open_hours":"","marker":{"url":"images/marker.png","scaledSize":{"width":25,"height":34,"f":"px","b":"px"},"origin":{"x":0,"y":0},"anchor":{"x":12,"y":42}},"iw":{"address":true,"desc":true,"email":true,"enable":true,"int_tel":true,"open":true,"open_hours":true,"photo":true,"tel":true,"title":true,"web":true}}
    ];
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            icon: locations[i].marker,
            position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
            map: map,
            title: locations[i].title,
            address: locations[i].address,
            desc: locations[i].desc,
            tel: locations[i].tel,
            int_tel: locations[i].int_tel,
            vicinity: locations[i].vicinity,
            open: locations[i].open,
            open_hours: locations[i].open_hours,
            photo: locations[i].photo,
            time: locations[i].time,
            email: locations[i].email,
            web: locations[i].web,
            iw: locations[i].iw
        });
        markersArray.push(marker);

        if (locations[i].iw.enable === true){
            bindInfoWindow(marker, map, locations[i]);
        }
    }
}


var vid = document.getElementById("my_vid");
$( "#video_btn" ).click(function() {
    if($(this).hasClass('active')) {
		vid.pause();
		$(this).removeClass('active');
	}
	else {
		vid.play();
		$(this).addClass('active');
    }
});

function playVid() {
	vid.play();
};
function pauseVid() {
	vid.pause();
};

$( "#info_modal a" ).click(function() {
	$(".close").click();
});

$( "#person1" ).click(function() {
	$("#info_title p").text("Дмитрий Валерианович Рыков");
	$("#info_subtitle p").text("Имплантология (Nobel Clinician), хирургия, костная пластика, ортопедия");
	$("#info_text").html('<p>Член Американской ассоциации имплантологов.</p><p>Медицинский стаж: более 20 лет</p><p>Окончил Московский медико-стоматологический университет имени Н.А. Семашко в 1991 году.</p><p>С 1991 по 1993 годы обучался в клинической ординатуре в "Центральном научно–исследовательском институте стоматологии и челюстно-лицевой хирургии"</p><p>Работает в клинике "Ладент" на Цветном с 2003 года.</p>');
});
$( "#person2" ).click(function() {
	$("#info_title p").text("Екатерина Борисовна Тимонина");
	$("#info_subtitle p").text("Имплантология (Zygoma), хирургия (синус-лифтинг), пародонтология, костная пластика.");
	$("#info_text").html('<p>Член Европейской ассоциации имплантологов.</p><p>Медицинский стаж: более 10 лет. Из них – 10 в клинике "Ладент".</p><p>Окончила Московский государственный медико-стоматологический университет в 2002 году.</p><p>В 2003 году завершила обучение в клинической ординатуре на кафедре госпитальной терапевтической стоматологии.</p><p>В 2005 году прошла обучение по специальности "Хирургическая стоматология" в Московском медицинском стоматологическом институте. </p>');
});
$( "#person3").click(function() {
	$("#info_title p").text("Андрей Геннадьевич Кодылев");
	$("#info_subtitle p").text("Имплантология, хирургия, костная пластика, ортопедия, Digital Smile Design.");
	$("#info_text").html('<p><p>Член Американской ассоциации стоматологов.</p><p>Медицинский стаж: более 15 лет.</p><p>Окончил Красноярскую государственную медицинскую академию в 2000 году.</p><p>В 2001 году завершил обучение в клинической интернатуре КрасГМА.</p><p>С 2009 по 2010 годы обучался с магистратуре Университета Лос-Анжелеса.</p><p>В 2016 году принял участие в международном симпозиуме Nobel Biocare Global Symposium 2016, (Нью Йорк, США). </p>');
});
$( "#person4").click(function() {
	$("#info_title p").text("Олег Вячеславович Нежинский");
	$("#info_subtitle p").text("Эстетическое протезирование (коронки, виниры).");
	$("#info_text").html('<p>Окончил Московский медико-стоматологический университет имени Н.А. Семашко в 2001 году.</p><p>С 2004 по 2005 годы проходил стажировку в клинике Dr.Russell A.Baer (Германия).</p><p>С 2005 года работает в клинике "Ладент" на Цветном бульваре.</p><p>В марте 2014 года принял участие в мастер-классе IPS e-max (революционная система цельнокерамический реставрации), Учебный центр Ivoclar Vivadent (Эльванген, Германия).</p><p>В мае 2015 года участвовал в практическом курсе   Галипа Гюреля "Компелксный подход к моделированию красивой улыбки в сложных клинических ситуациях".</p>');
});

$( "#person5").click(function() {
	$("#info_title p").text("Виктор Владимирович Левицкий");
	$("#info_subtitle p").text("Эстетическое протезирование.");
	$("#info_text").html('<p>Врач-ортопед высшей категории, специалист по эстетическому протезированию и имплантации, кандидат медицинских наук.</p><p>Медицинский стаж: более 10 лет.</p><p>Окончил Московский государственный медико-стоматологический университет.</p><p>В сентябре 2014 года принял участие в "Международном имплантологическом симпозиуме".</p><p>В мае 2015 года принял участие в практическом курсе Галипа Гюреля "Компелксный подход к моделированию красивой улыбки в сложных клинических ситуациях. Керамические виниры" (Стамбул, Турция).</p>');
});
$( "#person6").click(function() {
	$("#info_title p").text("Игорь Владимирович Баев");
	$("#info_subtitle p").text("Эстетическое протезирование.");
	$("#info_text").html('<p>Кандидат медицинских наук.</p><p>Медицинский стаж: более 8 лет.</p><p>Работает в клинике "Ладент на Башиловской" с 2015 года.</p><p>В 2007 году окончил Московский государственный медико-стоматологический университет.</p><p>В 2014 году окончил очную аспирантуру   на кафедре Госпитальной ортопедической стоматологии МГМСУ им. А.И. Евдокимова (г. Москва).</p><p>В 2016 году принял участие в участии в VII Международной научно-практической конференции по реконструктивной челюстно-лицевой хирургии (3-й Центральный военный клинический госпиталь им. А.А. Вишневского) (г. Красногорск).</p>');
});



google.maps.event.addDomListener(window, 'load', init_modal);
var map, markersArray = [];

function bindInfoWindow(marker, map, location) {
    google.maps.event.addListener(marker, 'click', function() {
        function close(location) {
            location.ib.close();
            location.infoWindowVisible = false;
            location.ib = null;
        }

        if (location.infoWindowVisible === true) {
            close(location);
        } else {
            markersArray.forEach(function(loc, index){
                if (loc.ib && loc.ib !== null) {
                    close(loc);
                }
            });

            var boxText = document.createElement('div');
            boxText.style.cssText = 'background: #fff;';
            boxText.classList.add('md-whiteframe-2dp');

            function buildPieces(location, el, part, icon) {
                if (location[part] === '') {
                    return '';
                } else if (location.iw[part]) {
                    switch(el){
                        case 'photo':
                            if (location.photo){
                                return '<div class="iw-photo" style="background-image: url(' + location.photo + ');"></div>';
                            } else {
                                return '';
                            }
                            break;
                        case 'iw-toolbar':
                            return '<div class="iw-toolbar"><h3 class="md-subhead">' + location.title + '</h3></div>';
                            break;
                        case 'div':
                            switch(part){
                                case 'email':
                                    return '<div class="iw-details"><i class="material-icons" style="color:#4285f4;"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><span><a href="mailto:' + location.email + '" target="_blank">' + location.email + '</a></span></div>';
                                    break;
                                case 'web':
                                    return '<div class="iw-details"><i class="material-icons" style="color:#4285f4;"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><span><a href="' + location.web + '" target="_blank">' + location.web_formatted + '</a></span></div>';
                                    break;
                                case 'desc':
                                    return '<label class="iw-desc" for="cb_details"><input type="checkbox" id="cb_details"/><h3 class="iw-x-details">Details</h3><i class="material-icons toggle-open-details"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><p class="iw-x-details">' + location.desc + '</p></label>';
                                    break;
                                default:
                                    return '<div class="iw-details"><i class="material-icons"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><span>' + location[part] + '</span></div>';
                                    break;
                            }
                            break;
                        case 'open_hours':
                            var items = '';
                            if (location.open_hours.length > 0){
                                for (var i = 0; i < location.open_hours.length; ++i) {
                                    if (i !== 0){
                                        items += '<li><strong>' + location.open_hours[i].day + '</strong><strong>' + location.open_hours[i].hours +'</strong></li>';
                                    }
                                    var first = '<li><label for="cb_hours"><input type="checkbox" id="cb_hours"/><strong>' + location.open_hours[0].day + '</strong><strong>' + location.open_hours[0].hours +'</strong><i class="material-icons toggle-open-hours"><img src="//cdn.mapkit.io/v1/icons/keyboard_arrow_down.svg"/></i><ul>' + items + '</ul></label></li>';
                                }
                                return '<div class="iw-list"><i class="material-icons first-material-icons" style="color:#4285f4;"><img src="//cdn.mapkit.io/v1/icons/' + icon + '.svg"/></i><ul>' + first + '</ul></div>';
                            } else {
                                return '';
                            }
                            break;
                    }
                } else {
                    return '';
                }
            }

            boxText.innerHTML =
                buildPieces(location, 'photo', 'photo', '') +
                buildPieces(location, 'iw-toolbar', 'title', '') +
                buildPieces(location, 'div', 'address', 'location_on') +
                buildPieces(location, 'div', 'web', 'public') +
                buildPieces(location, 'div', 'email', 'email') +
                buildPieces(location, 'div', 'tel', 'phone') +
                buildPieces(location, 'div', 'int_tel', 'phone') +
                buildPieces(location, 'open_hours', 'open_hours', 'access_time') +
                buildPieces(location, 'div', 'desc', 'keyboard_arrow_down');

            var myOptions = {
                alignBottom: true,
                content: boxText,
                disableAutoPan: true,
                maxWidth: 0,
                pixelOffset: new google.maps.Size(-140, -40),
                zIndex: null,
                boxStyle: {
                    opacity: 1,
                    width: '280px'
                },
                closeBoxMargin: '0px 0px 0px 0px',
                infoBoxClearance: new google.maps.Size(1, 1),
                isHidden: false,
                pane: 'floatPane',
                enableEventPropagation: false
            };

            location.ib = new InfoBox(myOptions);
            location.ib.open(map, marker);
            location.infoWindowVisible = true;
        }
    });
}

function init_modal() {
    var mapOptions = {
        center: new google.maps.LatLng(55.7717137,37.62066359999994),
        zoom: 14,
        gestureHandling: 'auto',
        fullscreenControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: true,
        streetViewControl: false,
        draggable : true,
        clickableIcons: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#193341"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#2c5a71"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#29768a"},{"lightness":-37}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#3e606f"},{"weight":2},{"gamma":0.84}]},{"elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"weight":0.6},{"color":"#1a3541"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#2c5a71"}]}]
    }
    var mapElement = document.getElementById('map_modal_msp');
    var map = new google.maps.Map(mapElement, mapOptions);
    var locations = [
        {"title":"Цветной бульвар","address":"Москва, Россия, 127051","desc":"","tel":"","int_tel":"","email":"","web":"","web_formatted":"","open":"","time":"","lat":55.7717137,"lng":37.62066359999994,"vicinity":"Russia","open_hours":"","marker":{"url":"images/marker.png","scaledSize":{"width":25,"height":34,"f":"px","b":"px"},"origin":{"x":0,"y":0},"anchor":{"x":12,"y":42}},"iw":{"address":true,"desc":true,"email":true,"enable":true,"int_tel":true,"open":true,"open_hours":true,"photo":true,"tel":true,"title":true,"web":true}}
    ];
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            icon: locations[i].marker,
            position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
            map: map,
            title: locations[i].title,
            address: locations[i].address,
            desc: locations[i].desc,
            tel: locations[i].tel,
            int_tel: locations[i].int_tel,
            vicinity: locations[i].vicinity,
            open: locations[i].open,
            open_hours: locations[i].open_hours,
            photo: locations[i].photo,
            time: locations[i].time,
            email: locations[i].email,
            web: locations[i].web,
            iw: locations[i].iw
        });
        markersArray.push(marker);

        if (locations[i].iw.enable === true){
            bindInfoWindow(marker, map, locations[i]);
        }
    }
}

function init_modal2() {
    var mapOptions = {
        center: new google.maps.LatLng(55.79419799999999,37.58889620000002),
        zoom: 14,
        gestureHandling: 'auto',
        fullscreenControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: true,
        streetViewControl: false,
        draggable : true,
        clickableIcons: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#193341"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#2c5a71"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#29768a"},{"lightness":-37}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#406d80"}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#3e606f"},{"weight":2},{"gamma":0.84}]},{"elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"weight":0.6},{"color":"#1a3541"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#2c5a71"}]}]
    }
    var mapElement = document.getElementById('map_modal_msp');
    var map = new google.maps.Map(mapElement, mapOptions);
    var locations = [
        {"title":"Савеловская","address":"Москва, Россия, 127015","desc":"","tel":"","int_tel":"","email":"","web":"","web_formatted":"","open":"","time":"","lat":55.79419799999999,"lng":37.58889620000002,"vicinity":"Russia","open_hours":"","marker":{"url":"images/marker.png","scaledSize":{"width":25,"height":34,"f":"px","b":"px"},"origin":{"x":0,"y":0},"anchor":{"x":12,"y":42}},"iw":{"address":true,"desc":true,"email":true,"enable":true,"int_tel":true,"open":true,"open_hours":true,"photo":true,"tel":true,"title":true,"web":true}}
    ];
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            icon: locations[i].marker,
            position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
            map: map,
            title: locations[i].title,
            address: locations[i].address,
            desc: locations[i].desc,
            tel: locations[i].tel,
            int_tel: locations[i].int_tel,
            vicinity: locations[i].vicinity,
            open: locations[i].open,
            open_hours: locations[i].open_hours,
            photo: locations[i].photo,
            time: locations[i].time,
            email: locations[i].email,
            web: locations[i].web,
            iw: locations[i].iw
        });
        markersArray.push(marker);

        if (locations[i].iw.enable === true){
            bindInfoWindow(marker, map, locations[i]);
        }
    }
}

$("#metro1").click(function() {
    setTimeout(init_modal, 200);
});
$("#metro2").click(function() {
    setTimeout(init_modal2, 200);
});
$("header a.callbtn.btn_style").click(function() {
	$("img#modal_img").attr('src','images/modal1.jpg');
	$("p#modal_text").html('<b>Андрей Геннадьевич Кодылев</b>, Ортопед, имплантолог, хирург');
});

$("#f_btn").click(function() {
    $("img#modal_img").attr('src','images/modal2.jpg');
    $("p#modal_text").html('<b>Дмитрий Валерианович Рыков</b>, Главный врач клиники "Ладент" на Цветном бульваре');
});
$("#content12 a.btn_style2").click(function() {
    $("img#modal_img").attr('src','images/modal3.jpg');
    $("p#modal_text").html('<b>Екатерина Борисовна Тимонина</b>, Главный врач клиники "Ладент" на Башиловской');
});

